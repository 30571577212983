import {
	RESET_INTERFACE_STATE,
	SET_LOADING,
	SET_SNACKBAR_MESSAGE,
	CLEAR_SNACKBAR_MESSAGE,
} from '../actions/types';

const initialState = {
	loading: false,
	snackbar: {
		status: '',
		title: '',
		message: '',
	},
};

export const userInterface = (state = initialState, action) => {
	switch (action.type) {
		case SET_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		case SET_SNACKBAR_MESSAGE: {
			return {
				...state,
				snackbar: {
					status: action.payload.status,
					title: action.payload.title,
					message: action.payload.message,
				},
			};
		}
		case CLEAR_SNACKBAR_MESSAGE:
			return {
				...state,
				snackbar: {
					status: '',
					title: '',
					message: '',
				},
			};
		case RESET_INTERFACE_STATE:
			return initialState;
		default:
			return state;
	}
};
