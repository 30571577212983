// lib
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// mui
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

// components
import CustomSpinner from 'components/UIKit/CustomSpinner/CustomSpinner';
import SetupEcoCard from 'pages/Process/EcoCard/SetupEcoCard';
import EcoCardUserInfo from 'pages/Process/EcoCard/EcoCardUserInfo';
import { getMissingEcoCardInfo } from 'redux/actions/auth';

export default function StartSetupEcoCard({ returnToPaymentSelect }) {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const [userInfoComplete, setUserInfoComplete] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [missingInfo, setMissingInfo] = useState(null);

	useEffect(() => {
		dispatch(getMissingEcoCardInfo())
			.then((res) => {
				setUserInfoComplete(true);
				setIsLoading(false);
			})
			.catch((err) => {
				const missingFields = err.response?.data?.missing_fields?.length > 0;
				if (missingFields) {
					setMissingInfo(err.response?.data.missing_fields);
				} else if (!missingFields) {
					setUserInfoComplete(true);
				}
				setIsLoading(false);
			});

		setIsLoading(false);
	}, []);

	return (
		<>
			{isLoading && <CustomSpinner text='One moment please...' />}
			{!isLoading && userInfoComplete && (
				<SetupEcoCard returnToPaymentSelect={returnToPaymentSelect} />
			)}
			{!isLoading && !userInfoComplete && (
				<EcoCardUserInfo
					missingInfo={missingInfo}
					returnToPaymentSelect={returnToPaymentSelect}
				/>
			)}
		</>
	);
}
