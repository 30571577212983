// lib
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// mui
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

// components
import { MODAL_ECOCARD_SETUP_SUCCESS, MODAL_GENERAL_ERROR } from 'redux/actions/types';
import EcoCardInstructions from 'pages/Process/EcoCard/EcoCardInstructions';
import alertOperatorIcon from 'assets/images/recycletek-get-operator-icon.png';
import { setupEcoCard } from 'redux/actions/user';
import { employeeLogin } from 'redux/actions/auth';
import CustomSpinner from 'components/UIKit/CustomSpinner/CustomSpinner';
import CustomButton from 'components/UIKit/CustomButton';
import AdminInput from 'components/Admin/AdminInput';

const useStyles = makeStyles((theme) => ({
	main: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		marginBottom: '100px',
	},
	section: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	icon: {
		height: '220px',
		width: 'auto',
	},
	buttonContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-around',
		position: 'absolute',
		bottom: '10px',
	},
}));

export default function SetupEcoCard({ returnToPaymentSelect }) {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const employee = useSelector((state) => state.auth.employee);
	const [step, setStep] = useState(1);
	const [password, setPassword] = useState('');
	const [barcode, setBarcode] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const instructions = [
		'ecoCardSetup.instructions.one',
		'ecoCardSetup.instructions.two',
		'ecoCardSetup.instructions.three',
	];

	const back = () => {
		if (step === 1 && history.location.state?.setupEcoCard) {
			history.push('/express');
		} else if (step === 1) {
			returnToPaymentSelect();
		} else if (step !== 1) {
			setStep((prevState) => prevState - 1);
		}
	};

	const handleEcoCardSetup = () => {
		dispatch(setupEcoCard(barcode))
			.then(() => {
				dispatch({
					type: MODAL_ECOCARD_SETUP_SUCCESS,
					payload: {
						message: t('messages.modal.ecoCardSetupSuccess'),
						heading: t('messages.modal.success'),
					},
				});
			})
			.catch((err) => {
				const errorInfo = err.response?.data?.message;

				const getErrMsgInfo = () => {
					const msg = err.response?.data.message;
					const accountInfo =
						'first_name' ||
						'last_name' ||
						'phone_number' ||
						'housenumber' ||
						'street' ||
						'state' ||
						'zipcode';
					const incompleteAccountInfo = msg?.includes(accountInfo);

					if (err.response?.status === 400 && incompleteAccountInfo) {
						return t('messages.modal.ecoCardIncompleteInfo');
					} else {
						return `${errorInfo ? errorInfo : err}`;
					}
				};

				dispatch({
					type: MODAL_GENERAL_ERROR,
					payload: {
						message: t('messages.modal.failedToSetupEcoCard'),
						info: getErrMsgInfo(),
						heading: t('messages.modal.error'),
					},
				});
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleSubmit = () => {
		setIsLoading(true);
		dispatch(employeeLogin(employee.username, password))
			.then(() => {
				handleEcoCardSetup();
			})
			.catch(() => {
				dispatch({
					type: MODAL_GENERAL_ERROR,
					payload: {
						message: t('admin.couldNotLogin'),
						heading: t('messages.modal.error'),
					},
				});
				setIsLoading(false);
			});
	};

	const nextStep = () => {
		if (step === 3) {
			handleSubmit();
		} else if (step !== 3) {
			setStep((prevState) => prevState + 1);
		}
	};

	return (
		<>
			{!isLoading && (
				<Box className={classes.main}>
					<EcoCardInstructions step={step} text={instructions[step - 1]} />
					<Box className={classes.section}>
						{step === 1 && (
							<img
								className={classes.icon}
								src={alertOperatorIcon}
								alt='alert operator icon'
							/>
						)}
						{step === 2 && (
							<AdminInput
								label={t('ecoCardSetup.barcodeNumber')}
								variant='secondary'
								value={barcode}
								autoFocus
								handleChange={(e) => setBarcode(e.target.value)}
							/>
						)}
						{step === 3 && (
							<AdminInput
								label={t('common.buttons.adminPassword')}
								variant='secondary'
								autoFocus
								value={password}
								isPassword={true}
								handleChange={(e) => setPassword(e.target.value)}
							/>
						)}
					</Box>

					<Box className={classes.buttonContainer}>
						<CustomButton variant='alternate' onClick={back}>
							{t('common.buttons.back')}
						</CustomButton>
						<CustomButton disabled={isLoading} onClick={nextStep}>
							{t('common.buttons.continue')}
						</CustomButton>
					</Box>
				</Box>
			)}
			{isLoading && <CustomSpinner text='Setting up EcoCard, one moment please...' />}
		</>
	);
}
