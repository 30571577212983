import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import MuiAlert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
	container: {
		'& .MuiSnackbarContent-root': {
			minWidth: '100px',
			textAlign: 'center',
		},
	},
}));

function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />;
}

// if no status is given as props, a generic message will be displayed at the bottom of the screen
// if a status is provided, an error/success/warning message will be displayed at the top of the screen
export default function CustomSnackbar({
	message,
	status,
	close,
	autoHideDuration,
	showCloseButton,
	title,
}) {
	const classes = useStyles();

	return (
		<Box className={classes.container}>
			{!status && (
				<Snackbar
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					open={message}
					message={message}
					autoHideDuration={autoHideDuration}
					ClickAwayListenerProps={{ onClickAway: () => null }}
					onClose={close}
					action={
						<>
							{showCloseButton && (
								<IconButton
									size='small'
									aria-label='close'
									color='inherit'
									onClick={close}
								>
									<CloseIcon fontSize='small' />
								</IconButton>
							)}
						</>
					}
				/>
			)}
			{status && (
				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
					open={message}
					autoHideDuration={autoHideDuration ?? 8000}
					ClickAwayListenerProps={{ onClickAway: () => null }}
					onClose={close}
				>
					<Alert
						onClose={close}
						severity={status}
						action={
							<>
								{showCloseButton && (
									<IconButton
										size='small'
										aria-label='close'
										color='inherit'
										onClick={close}
									>
										<CloseIcon fontSize='small' />
									</IconButton>
								)}
							</>
						}
					>
						{title && <AlertTitle>{title}</AlertTitle>}
						{message}
					</Alert>
				</Snackbar>
			)}
		</Box>
	);
}
