import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactCodeInput from 'react-verification-code-input';

// mui
import { Dialog, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// components
import { fullLogin } from 'redux/actions/auth';
import { RESET_TWO_FACTOR_AUTH } from 'redux/actions/types';
import CustomButton from 'components/UIKit/CustomButton';

const useStyles = makeStyles((theme) => ({
	container: {
		padding: '20px',
	},
	numberInput: {
		minWidth: '100%',
		textAlign: 'center',
		marginTop: '50px',
		marginBottom: '30px',
	},
	checkbox: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		marginBottom: '20px',
	},
	buttonGroup: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		'& button': {
			marginRight: '20px',
			marginLeft: '20px',
		},
	},
}));

export default function TwoStepAuthModal({ open, close, emailOrPhone, password, updateCustomer }) {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const twoFactorAuth = useSelector((state) => state.auth.twoFactorAuth);
	const [token, setToken] = useState('');
	const classes = useStyles();

	const handleCancel = () => {
		dispatch({ type: RESET_TWO_FACTOR_AUTH });
		close();
	};

	const handleSubmit = () => {
		dispatch(fullLogin(emailOrPhone, password, token)).then((res) => {
			res.access_token && updateCustomer(res.access_token);
		});
	};

	return (
		<>
			<Dialog
				open={open}
				PaperProps={{
					style: { borderRadius: '10px' },
				}}
			>
				<Box className={classes.container}>
					<Typography variant='h5'>{t('twoStepAuth.header')}</Typography>
					<Typography>
						{twoFactorAuth.value
							? t('twoStepAuth.sentCode', { location: twoFactorAuth.value })
							: t('twoStepAuth.tryAgain')}
					</Typography>
					<ReactCodeInput
						id='verification_code'
						name='verification code'
						type='number'
						fields={6}
						autoFocus={false}
						className={classes.numberInput}
						onChange={(e) => setToken(e)}
					/>
					<Box className={classes.buttonGroup}>
						<CustomButton onClick={handleCancel}>{t('common.buttons.cancel')}</CustomButton>
						<CustomButton onClick={handleSubmit}>{t('overlay.submit')}</CustomButton>
					</Box>
				</Box>
			</Dialog>
		</>
	);
}
