import { SET_LOADING, SET_SNACKBAR_MESSAGE } from 'redux/actions/types';

export const setLoading = (state) => (dispatch) => {
	dispatch({ type: SET_LOADING, payload: state });
};

export const setSnackbarMessage = (message, type, title) => (dispatch) => {
	dispatch({
		type: SET_SNACKBAR_MESSAGE,
		payload: {
			message: message,
			status: type ?? '',
			title: title ?? '',
		},
	});
};
