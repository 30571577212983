import React from 'react';
import Background from 'components/Background/Background';
import CustomBox from 'components/UIKit/CustomBox';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

// *** put component in development here **
// Eg. import Example from 'components/Examples/Example';

const useStyles = makeStyles((theme) => ({
	container: {},
}));

export default function DevelopmentSandbox() {
	const classes = useStyles();

	return (
		<Background>
			<CustomBox title='Development Sandbox'></CustomBox>
		</Background>
	);
}
