// lib
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

// mui
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { connectionSpeed } from 'utils/connection';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { Box } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';

// components
import {
	SET_DRYRUN,
	SET_THROW_ERROR,
	SET_VIEW_ECOCARD_SETUP,
	SET_IS_EMAIL_RECEIPT_ON,
	SET_DEMO_MODE,
} from '../../redux/actions/types';

const useStyles = makeStyles((theme) => ({
	infoPanel: {
		display: 'flex',
		flexDirection: 'column',
		minWidth: '400px',
		fontSize: '12px',
		marginLeft: '18px',
		padding: '10px',
		borderRadius: '4px',
		'& .MuiTypography-root': {
			color: theme.palette.text.primary,
		},
		'& .MuiDivider-root': {
			margin: '20px 0px 10px 0px',
		},
	},
	info: {
		display: 'flex',
		flexDirection: 'column',
		'& .MuiTypography-root': {
			margin: '6px 0px',
		},
	},
	controls: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'start',
	},
	panelLink: {
		marginTop: '10px',
		marginLeft: '15px',
	},
	toggle: {},
}));

export default function DevelopmentInfoPanel({ dryrun, throwError }) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const showEcoCardSetup = useSelector((state) => state.admin.forceEcoCardSetup);
	const demoMode = useSelector((state) => state.admin.demoMode);
	const emailReceipt = useSelector((state) => state.admin.isEmailReceiptOn);
	const networkSpeed = useSelector((state) => state.admin.networkSpeed);
	const employee = useSelector((state) => state.auth.employee);
	const version = useSelector((state) => state.admin.versioning);
	const [date, setDate] = useState();
	const [environment, setEnvironment] = useState();

	useEffect(() => {
		getEnvironment();
		getDate();
		connectionSpeed();
	}, []);

	const getEnvironment = () => {
		let env = '';
		const hostName = window.location.hostname;

		if (hostName === 'localhost') {
			env = 'Development - local';
		} else if (hostName === 'kiosk.recycletek.ddns.net') {
			env = 'Development - staging';
		} else if (hostName === 'kiosk.recycletek.co') {
			env = 'Production';
		}

		setEnvironment(env);
	};

	const getDate = () => {
		const date = new Date().toString();

		setDate(date);
	};

	const handleDryrunChange = (e) => {
		dispatch({ type: SET_DRYRUN, payload: !dryrun });
	};

	const handleThrowErrorChange = () => {
		dispatch({ type: SET_THROW_ERROR, payload: !throwError });
	};

	const handleEcoCardSetupChange = () => {
		dispatch({ type: SET_VIEW_ECOCARD_SETUP, payload: !showEcoCardSetup });
	};

	const handleDemoModeChange = () => {
		dispatch({ type: SET_DEMO_MODE, payload: !demoMode });
	};

	const handleConfirmationEmailChange = () => {
		dispatch({ type: SET_IS_EMAIL_RECEIPT_ON, payload: !emailReceipt });
	};

	return (
		<Box className={classes.infoPanel}>
			<Box className={classes.info}>
				<Typography variant='caption'>Environment: {environment}</Typography>
				<Typography variant='caption'>{date}</Typography>
				<Typography variant='caption'>Connection Speed Estimate: {networkSpeed}</Typography>
				<Typography variant='caption'>Employee: {employee.username}</Typography>
			</Box>
			<Divider />
			<Box className={classes.versioning}>
				<Typography variant='caption'>Interface v - {version.interface.v}</Typography>
				<br />
				<Typography variant='caption'>commit: {version.interface.git}</Typography>
				<br />
				<br />
				<Typography variant='caption'>Client v - {version.client.v}</Typography>
				<br />
				<Typography variant='caption'>commit: {version.client.git}</Typography>
			</Box>
			<Divider />
			<Box className={classes.controls}>
				<FormControlLabel
					className={classes.toggleLabel}
					label={
						<Typography variant='caption'>{`Dryrun is ${dryrun ? 'ON' : 'OFF'}`}</Typography>
					}
					labelPlacement='start'
					control={
						<Switch
							className={classes.toggle}
							inputProps={{ 'aria-label': 'primary checkbox' }}
							checked={dryrun}
							onChange={handleDryrunChange}
						/>
					}
				/>
				<FormControlLabel
					className={classes.toggleLabel}
					label={
						<Typography variant='caption'>{`Force Error ${
							throwError ? 'ON' : 'OFF'
						}`}</Typography>
					}
					labelPlacement='start'
					control={
						<Switch
							className={classes.toggle}
							inputProps={{ 'aria-label': 'primary checkbox' }}
							checked={throwError}
							onChange={handleThrowErrorChange}
						/>
					}
				/>
				<FormControlLabel
					className={classes.toggleLabel}
					label={<Typography variant='caption'>Show EcoCard Setup</Typography>}
					labelPlacement='start'
					control={
						<Switch
							className={classes.toggle}
							inputProps={{ 'aria-label': 'primary checkbox' }}
							checked={showEcoCardSetup}
							onChange={handleEcoCardSetupChange}
						/>
					}
				/>
				<FormControlLabel
					className={classes.toggleLabel}
					label={<Typography variant='caption'>Demo Mode</Typography>}
					labelPlacement='start'
					control={
						<Switch
							className={classes.toggle}
							inputProps={{ 'aria-label': 'primary checkbox' }}
							checked={demoMode}
							onChange={handleDemoModeChange}
						/>
					}
				/>
				<FormControlLabel
					className={classes.toggleLabel}
					label={<Typography variant='caption'>Email Receipt</Typography>}
					labelPlacement='start'
					control={
						<Switch
							className={classes.toggle}
							inputProps={{ 'aria-label': 'primary checkbox' }}
							checked={emailReceipt}
							onChange={handleConfirmationEmailChange}
						/>
					}
				/>
				{window.location.hostname === 'localhost' && (
					<Box className={classes.panelLink}>
						<NavLink to='/sandbox'>
							<Typography variant='caption'>Development Sandbox</Typography>
						</NavLink>
					</Box>
				)}
			</Box>
		</Box>
	);
}
